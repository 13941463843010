$(document).ready(function(){
  // Member Manager
  $member_manager = $('.member-manager');

  $member_manager.on('click', '.add', function(event){

    var $this     = $(this)
      , $manager  = $this.closest('.member-manager')
      , $wizard   = $this.closest('ui-formwizard')
      , index     = $manager.find('.member-form').length + 1
      , template  = $manager.data('template')
      , content   = template.replace(/{{index}}/g, index)

    event.preventDefault();

    $this.before(content);
    // $wizard.formwizard('reset');
  })

  $member_manager.on('click', '.remove', function(event){
    var $this = $(this)
      , $manager  = $this.closest('.member-manager')
      , $table = $this.closest('.member-form')

    event.preventDefault();
    $table.remove();
    $manager.trigger('reindex');

  })

  $member_manager.on('reindex', function(event){
    var $this   = $(this)
      , $forms = $this.find('.member-form')

    $forms.each(function(i){
      var $form   = $(this)
        , index   = (i + 1)

      if ($form.data('index') != index ) {

        $form.find('input').each(function(){
          var $input  = $(this)
            , name    = $input.attr('name')

          $input.attr('name', name.replace(/\[\d\]/g, '[' + index + ']'))

        })

        $form.data('index', index);
      }
    })
  })
})
