$(function () {
    $('.form').each(function () {
        var $this = $(this)
        var submitLabel = $(this).find('input[type=submit]').val()

        $this.formwizard({
            validationEnabled: true,
            focusFirstInput: true,
            textSubmit: submitLabel
        })
    })

    $('#membership_organization_type').on('change', function () {
        var type = $(this).val()
        var isAffiliate = type.startsWith('affiliate')

        if (isAffiliate) {
            $('#membership_member_limit_7').prop('checked', true)
            $('.membership_member_limit').hide()
        } else {
            $('.membership_member_limit').show()
        }
    }).trigger('change')

    // UPDATE FORM ACTIONS
    $('.submit .delete_many').click(function () {
        $('#form_action').val('delete_many')
    })

    // USE SIZE ON INPUTS WHEN SET
    $('.form input[size]').each(function (i, el) {
        $(el).css('width', 'auto')
    })

    $('#cart tbody tr:odd').addClass('odd')
    $('#cart tbody tr:even').addClass('even')

    $('textarea.limit').keypress(function (event) {
        var limit = $(this).data('limit')
        var length = $(this).val().length
        if (length >= limit) {
            event.preventDefault()
        }
        $(this).siblings('.limit-text').html(limit - length)
    }).trigger('keypress')

    // FILTER
    $('#filter select').change(function () {
        $(this).closest('form').submit()
    })

    // Award attachment toggle
    $('.attach-document').on('click', 'a', function (event) {
        event.preventDefault()
        $('.attach-document').hide().find('input').val('')
        $('.attach-url').show()
    })

    $('.attach-url').on('click', 'a', function (event) {
        event.preventDefault()
        $('.attach-url').hide().find('input').val('')
        $('.attach-document').show()
    })

    $('#registration .attendance').on('change', function (event) {
        var target = $(event.target)
        if (!target.prop('checked')) {
            return
        }
        var attend = $('#conference_registration_attend')
        var thursday = $('#sessions_thursday').clone()
        var friday = $('#sessions_friday').clone()

        // Remove all session
        $('#registration .session').remove()

        if (target.val() === 'thursday') {
            thursday.insertAfter('#conference_registration_attend')
        }
        if (target.val() === 'friday') {
            friday.insertAfter('#conference_registration_attend')
        }
        if (target.val().startsWith('both')) {
            friday.insertAfter('#conference_registration_attend')
            thursday.insertAfter('#conference_registration_attend')
        }
        $('#registration').formwizard('update_steps')
    }).trigger('change')

    $('#entry').bind('step_shown', function (event, data) {
        var submitDiv = $('.save-button-holder')
        var stepsText = $('#steps')
        var step

        // Convert steps to array of id values for each step
        var steps = $.map(data.steps, function (value, index) {
            return [value.id]
        })

        // Find what step we are on, then account for index offset
        step = steps.indexOf(data.currentStep) + 1

        // Ensures the save button is appended only once and not on every event call
        if (submitDiv.children().length < 1) {
            submitDiv.append(`
                <input id="save" name="save" type='submit' value='Save My Work'
                    class='ui-wizard-content ui-formwizard-button ui-helper-reset ui-state-default ui-state-active'
                />`
            )
            $('#save').click(function (event) {
                // Overwrites all the form data that is sent to the $_POST object when the save button is clicked
                // Ensures that all data from every page appears in the $_POST object
                $('#entry').find(':input').each(function (i, param) {
                    $('<input />').attr('type', 'hidden')
                        .attr('name', param.name)
                        .attr('value', param.value)
                        .appendTo('#entry')
                })
            })
        }

        stepsText.text('Step ' + step + ' of 7')
    })
})
